import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionTitle,
} from "../../styles/global.syles";
import { LotterDisplayContent } from "./index.styles";
// import * as imageArray from "../../assets/images/testimonials";

const Testimonials = () => {
  return (
    <SectionContainer bg="white">
      <SectionContent>
        {/* --------------- */}
        {/* Section header  */}
        {/* --------------- */}
        <SectionHeader>
          {/* Title  */}
          <SectionTitle>Testimonials</SectionTitle>
        </SectionHeader>
        {/* --------------- */}
        {/* Section body  */}
        {/* --------------- */}
        <LotterDisplayContent>
          <Carousel
            showArrows
            showThumbs={false}
            infiniteLoop
            autoPlay
            showStatus={false}
            className="carousel_radius"
          >
            {arr.map((n) => (
              <div style={{ borderRadius: "20px", overflow: "hidden" }}>
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: "20px",
                    overflow: "hidden",
                    height: "80vw",
                    maxHeight: "500px",
                    minHeight: "300px",
                  }}
                  width={200}
                  src={n}
                  loading="lazy"
                  alt={`${n} image`}
                />
              </div>
            ))}
          </Carousel>
        </LotterDisplayContent>
      </SectionContent>
    </SectionContainer>
  );
};

export default Testimonials;

let arr = [
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F1.jpeg?alt=media&token=af925e05-10d9-474c-804c-30f20beaa1eb",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F2.jpeg?alt=media&token=4f2c1ff7-1b8f-4f29-8e90-52b62bef6173",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F3.jpeg?alt=media&token=dc559ca0-ea84-4d5f-a2a1-f7fbe0deb57f",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F4.jpeg?alt=media&token=b0f874cf-d0a5-437e-be26-878d74f5c29e",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F5.jpeg?alt=media&token=424adfb7-640a-48e8-96df-6352d22dbf9e",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F6.jpeg?alt=media&token=5aeabc67-95a2-4afe-ab54-42cae0767cc5",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F7.jpeg?alt=media&token=1a90c088-f4b5-40c1-bdc1-8829d58ef930",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F8.jpeg?alt=media&token=9a6dc22a-2ded-4230-91fb-c250a6a94989",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F9.jpeg?alt=media&token=6fd25b48-6cf3-480a-aab4-90847b9958cd",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F10.jpeg?alt=media&token=7364c91b-825c-4fad-b4e6-21490cc1df4c",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F11.jpeg?alt=media&token=05f41417-221a-42d1-9b08-cb95224e3c1f",
  "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2F12.jpeg?alt=media&token=03a37097-fbfd-49b2-b31a-16ed361e9e92",
];

import React from "react";
import {
  Flex,
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionSubTitle,
  SectionTitle,
} from "../../styles/global.syles";
import { LotteryAfter, LotteryBefore, LotteryContent } from "./Lottery.styles";

const Lottery = () => {
  return (
    <SectionContainer id="features" bg="#f1fbf2">
      <SectionContent>
        {/* --------------- */}
        {/* Section header  */}
        {/* --------------- */}
        <SectionHeader>
          {/* Title  */}
          <SectionTitle fontSize="43px" color="#06240A">
            Give your lottery a facelift
          </SectionTitle>
          {/* Subtitle  */}
          <SectionSubTitle color="#06240A">
            Free your counter space.. see the before and after..
          </SectionSubTitle>
        </SectionHeader>
        {/* --------------- */}
        {/* Section body  */}
        {/* --------------- */}
        <LotteryContent>
          <Flex gap="40px" grow="1" className="lottery-content-flex">
            <LotteryBefore>
              <div className="img">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Flottery-before.png?alt=media&token=a5ee2601-3fdd-47d5-855f-3b9d43842b21"
                  }
                  loading="lazy"
                  alt="Shopping"
                />
                <button>Before</button>
              </div>
            </LotteryBefore>
            <LotteryAfter>
              <div className="img">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Flottery-after.png?alt=media&token=f8783bc8-746d-4271-a6b6-79dfbd295280"
                  }
                  loading="lazy"
                  alt="Shopping"
                />
                <button>After</button>
              </div>
            </LotteryAfter>
          </Flex>
        </LotteryContent>
      </SectionContent>
    </SectionContainer>
  );
};

export default Lottery;

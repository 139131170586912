import React from "react";
import {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionSubTitle,
  SectionTitle,
} from "../../styles/global.syles";
import { LotterDisplayContent } from "./LotteryDisplay.styles";

const LotteryDisplay = () => {
  return (
    <SectionContainer bg="white">
      <SectionContent>
        {/* --------------- */}
        {/* Section header  */}
        {/* --------------- */}
        <SectionHeader>
          {/* Title  */}
          <SectionTitle>Huge Lottery Display</SectionTitle>
          {/* Subtitle  */}
          <SectionSubTitle>
            4K High Resolution huge display for clear ticket image, selling
            amount & box display along with live updates for lotto prices.
          </SectionSubTitle>
        </SectionHeader>
        {/* --------------- */}
        {/* Section body  */}
        {/* --------------- */}
        <LotterDisplayContent>
          <div className="img">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Ftv.png?alt=media&token=fbfe6a7d-68eb-4d08-af31-352a67d8c83b"
              }
              loading="lazy"
              alt="Shopping"
            />
          </div>
        </LotterDisplayContent>
      </SectionContent>
    </SectionContainer>
  );
};

export default LotteryDisplay;
